const API_URL = process.env.REACT_APP_API_URL;

/**
 * Creates a new group with the specified recipient IDs.
 *
 * @param {Object} groupData The data for the new group.
 * @param {Array<string>} recipientIds The IDs of the recipients to add to the group.
 * @returns {Promise<string>} A promise that resolves to the unique identifier of the newly created group.
 */
export const createGroup = async (payload) => {
    const response = await fetch(`${API_URL}/groups/group`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.text(); // Assuming the response is just the unique identifier as a string
};

/**
 * Retrieves all groups with their recipients for a specific organization.
 *
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to a list of groups with their recipients.
 */
export const getGroupsWithRecipientsByOrganization = async (organizationId) => {
    const response = await fetch(`${API_URL}/groups/organization/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON array of groups with recipients
};