import { Button, Card, CardBody, CardHeader, useDisclosure } from "@nextui-org/react";
import { CardTitle } from "react-bootstrap";
import Navigation from "../components/Navigation";
import CreateOrganizationModal from "../components/CreateOrganizationModal";
import { getClientAdminOrganizations, getUserById } from "../services/userServices";
import { useEffect, useState } from "react";
import ViewOrganizationModal from "../components/ViewOrganizationModal";
import { useNavigate } from "react-router-dom";

export default function BrandAdminDashboard({ userData, setUserInformation }) {
    const { isOpen: isCreateOrgModalOpen, onOpen: onOpenCreateOrgModal, onOpenChange: onOpenChangeCreateOrgModal } = useDisclosure();
    const { isOpen: isViewOrgModalOpen, onOpen: onOpenViewOrgModal, onClose: onCloseViewOrgModal } = useDisclosure();
    const [organizations, setOrganizations] = useState([]); // State to hold organizations
    const [selectedOrg, setSelectedOrg] = useState(null); // State for selected organization data
    const navigate = useNavigate();


    const refreshUserData = async () => {
        // Assuming you have a function to fetch user data by ID
        const updatedUserData = await getUserById(userData._id);
        setUserInformation(updatedUserData);
    };

    const fetchAndSetOrganizations = async (userId) => {
        try {
            const orgs = await getClientAdminOrganizations(userId);
            setOrganizations(orgs);
        } catch (error) {
            console.error('Error fetching organizations:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleOpenViewOrgModal = (orgData) => {
        setSelectedOrg(orgData); // Set the selected organization data
        onOpenViewOrgModal(); // Open the ViewOrganizationModal
    };

    // Call fetchAndSetOrganizations when the component mounts
    useEffect(() => {
        if (userData?._id) {
            fetchAndSetOrganizations(userData._id);
        }
    }, [userData]);

    return (
        <div>
            <Navigation userData={userData} />
            <CreateOrganizationModal
                isOpen={isCreateOrgModalOpen}
                onOpenChange={onOpenChangeCreateOrgModal}
                userData={userData}
                refreshUserData={refreshUserData}
            />
            <ViewOrganizationModal
                isOpen={isViewOrgModalOpen}
                onOpenChange={onCloseViewOrgModal}
                organizationData={selectedOrg}
            />
            <Button
                style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    marginLeft: '1.5rem',
                    marginRight: '1.5rem',
                }}
                color="primary"
                onClick={onOpenCreateOrgModal} // Open CreateOrganizationModal
            >
                Create Organization
            </Button>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {organizations.map(org => (
                    <Card
                        key={org._id}
                        style={{ maxWidth: '400px', margin: '10px', cursor: 'pointer' }}
                        isPressable={true}
                        onPress={() => {
                            localStorage.setItem('orgId', org._id)
                            navigate('/org-admin-dashboard')
                        }} // Open ViewOrganizationModal
                    >
                        <CardHeader>
                            <CardTitle>{org.name}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <p>Organization ID: {org._id}</p>
                            <p>Organization Admin: {org?.users[0]?.first_name} {org?.users[0]?.last_name}</p>
                            <p>Organization Admin Email: <a href={`mailto:${org?.users[0]?.email}`} style={{ color: 'blue' }}>{org?.users[0]?.email}</a></p>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    )
}