import { useState } from 'react';
import { Modal, Input, Button, Tabs, Tab, Card, CardBody, ModalHeader, ModalBody, ModalContent } from "@nextui-org/react";
import RecipientSelect from './selectRecipientsTabs/RecipientSelect';
import CsvUpload from './selectRecipientsTabs/CsvUpload';
import GroupSelect from './selectRecipientsTabs/GroupSelect';

export default function SelectRecipientsModal({ isOpen, onOpenChange, organizationId, onSelectionChange, onCsvUploadComplete, onGroupIdsChange }) {
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const handleGroupSelectionChange = (groupRecipients) => {
        // Call the onSelectionChange prop with the group recipients
        onSelectionChange(groupRecipients);
    };

    const handleRecipientSelection = (recipientId, isSelected) => {
        setSelectedRecipients(prevSelected => {
            if (isSelected) {
                return [...prevSelected, recipientId];
            } else {
                return prevSelected.filter(id => id !== recipientId);
            }
        });
    };

    const handleCsvUploadComplete = (recipientIds) => {
        // Call the onCsvUploadComplete prop passed from CreateTest.js
        onCsvUploadComplete(recipientIds);
        closeModal(); // Close the modal after setting the selected recipients
    };

    const closeModal = () => {
        onOpenChange(false);
    };

    const handleGroupIdsChange = (groupIds) => {
        setSelectedGroups(groupIds); // Track the selected group IDs
        onGroupIdsChange(groupIds); // Pass the group IDs up to CreateTest.js
    };

    const tabs = [
        {
            id: "select",
            label: "Select",
            content: <RecipientSelect organizationId={organizationId} onSelectionChange={onSelectionChange} />
        },
        {
            id: "upload",
            label: "CSV Upload",
            content: <CsvUpload organizationId={organizationId} onUploadComplete={handleCsvUploadComplete} closeModal={closeModal} />
        },
        {
            id: "groups",
            label: "Groups",
            content: <GroupSelect organizationId={organizationId} onGroupSelectionChange={handleGroupSelectionChange} onGroupIdsChange={handleGroupIdsChange} />
        },
    ];

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            placement="top-center"
            style={{
                minWidth: '800px',
                minHeight: '400px'
            }}
        >
            <ModalContent>
                <ModalHeader>Add Recipients</ModalHeader>
                <ModalBody>
                    <Tabs items={tabs}>
                        {(item) => (
                            <Tab key={item.id} title={item.label} className="py-0">
                                {item.content}
                            </Tab>
                        )}
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}