import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Pagination, Modal, ModalHeader, ModalBody, ModalFooter, useDisclosure, ModalContent } from "@nextui-org/react";
import Navigation from "../components/Navigation";
import { useNavigate } from "react-router-dom";
import { deleteTemplateById, getTemplatesByOrganization } from '../services/templateService';

export default function Templates({ userData, orgInformation }) {
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 10; // Set the number of items per page
    const organizationId = orgInformation?._id; // Assuming userData contains organizationId
    const [total, setTotal] = useState(0);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [templateToDelete, setTemplateToDelete] = useState(null);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await getTemplatesByOrganization(organizationId, page, pageSize);
                setTemplates(response.templates);
                setTotal(response.total_count);
            } catch (error) {
                console.error('Failed to fetch templates:', error);
            }
        };

        fetchTemplates();
    }, [page, organizationId]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const openDeleteModal = (template) => {
        setTemplateToDelete(template);
        onOpen();
    };

    const closeDeleteModal = () => {
        setTemplateToDelete(null);
        onClose();
    };

    const handleDelete = async () => {
        if (templateToDelete) {
            try {
                // Call the service function to delete the template
                const response = await deleteTemplateById(templateToDelete._id);
                console.log(response.message); // Log the success message from the server

                // Remove the deleted template from the state
                setTemplates(templates.filter((template) => template._id !== templateToDelete._id));

                // Optionally, you can show a success toast/notification to the user here
            } catch (error) {
                console.error('Failed to delete template:', error);
                // Optionally, you can show an error toast/notification to the user here
            } finally {
                closeDeleteModal(); // Close the modal in any case
            }
        }
    };

    return (
        <div>
            <Navigation userData={userData} />
            <Modal isOpen={isOpen} onClose={onClose} placement="top-center">
                <ModalContent>
                    <ModalHeader>
                        Confirm Delete
                    </ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the template: <b>"{templateToDelete?.name}"?</b>
                    </ModalBody>
                    <ModalFooter>
                        <Button auto flat color="default" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                        <Button auto onClick={handleDelete} color="danger">
                            Confirm
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <Button
                    style={{ marginTop: '1rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={() => navigate('/org-admin-dashboard/create-template')}
                >
                    Create Template
                </Button>
                <Table
                    selectionMode='single'
                    bottomContent={
                        page > 0 ? (
                            <div className="flex w-full justify-center">
                                <Pagination
                                    isCompact
                                    showControls
                                    showShadow
                                    color="primary"
                                    page={page}
                                    total={Math.ceil(total / pageSize)}
                                    onChange={(page) => setPage(page)}
                                />
                            </div>
                        ) : null
                    }
                >
                    <TableHeader>
                        <TableColumn>Template name</TableColumn>
                        <TableColumn>Updated by</TableColumn>
                        <TableColumn>Last modified</TableColumn>
                        <TableColumn></TableColumn>
                        <TableColumn></TableColumn>
                        <TableColumn></TableColumn>
                    </TableHeader>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow key={template._id} onClick={() => navigate(`/org-admin-dashboard/template/${template._id}`)} >
                                <TableCell>{template.name}</TableCell>
                                <TableCell>{template.updated_by}</TableCell>
                                <TableCell>{template.updated_date.split('T')[0]}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Button color="danger" onClick={() => openDeleteModal(template)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div >
    )
}