import { Chip, Modal, ModalBody, ModalContent, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";

export default function ViewOrganizationModal({ isOpen, onOpenChange, organizationData }) {
    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="top-center"
            style={{ marginBottom: '10px', maxWidth: '500px' }}
        >
            <ModalContent>
                <ModalHeader>{organizationData?.name}</ModalHeader>
                <ModalBody>
                    <Table removeWrapper style={{ marginBottom: '30px' }}>
                        <TableHeader>
                            <TableColumn>Email</TableColumn>
                            <TableColumn>Status</TableColumn>
                        </TableHeader>
                        <TableBody style={{ maxWidth: '300px' }}>
                            {organizationData?.users.map((admin) => (
                                <TableRow key={admin._id} style={{ maxWidth: '300px' }}>
                                    <TableCell>
                                        <a href={`mailto:${admin?.email}`} style={{ color: 'blue' }}>{admin?.email}</a>
                                    </TableCell>
                                    <TableCell>
                                        {admin.invited ? (
                                            <Chip color="warning">Invite pending</Chip>
                                        ) : (
                                            <Chip style={{ backgroundColor: '#d2f4d3', color: '#1a7f64' }}>Active</Chip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}