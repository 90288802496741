// client/src/components/CreateOrganizationModal.js
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";
import { useState } from "react";
import { inviteOrganizationAdmin } from "../services/userServices"; // Import the service function
import { createEC2Instance } from "../services/organizationService"; // Import the new service function

export default function CreateOrganizationModal({ isOpen, onOpenChange, userData, refreshUserData }) {
    const [organizationName, setOrganizationName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [faviconUrl, setFaviconUrl] = useState('');
    const [primaryColor, setPrimaryColor] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (loading) return;

        setLoading(true);
        setError('');

        if (!organizationName || !firstName || !lastName || !email || !subdomain || !faviconUrl || !primaryColor) {
            setError('All fields are required');
            setLoading(false);
            return;
        }

        // Construct the invitation data object
        const inviteData = {
            organization_name: organizationName,
            brand_admin_id: userData?._id,
            first_name: firstName,
            last_name: lastName,
            email: email,
            subdomain: subdomain,
            favicon_url: faviconUrl,
            primary_color: primaryColor
        };

        try {
            // Call the service function to send the invitation
            const inviteResponse = await inviteOrganizationAdmin(inviteData);
            console.log('Organization admin invited successfully:', inviteResponse);

            // Call the new service function to create an EC2 instance
            const ec2Response = await createEC2Instance({
                instance_name: organizationName,
                subdomain: subdomain,
                favicon_url: faviconUrl,
                primary_color: primaryColor
            });
            console.log('EC2 instance creation initiated:', ec2Response);

            await refreshUserData();
            onOpenChange(false); // Close the modal on success
        } catch (error) {
            console.error('Error processing request:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            placement="top-center"
        >
            <ModalContent>
                <ModalHeader>Create Organization</ModalHeader>
                <ModalBody>
                    {error && <Code color="danger">{error}</Code>}
                    <Input variant="bordered" placeholder="Organization Name" onChange={(e) => setOrganizationName(e.target.value)} value={organizationName} />
                    <b>Organization Admin</b>
                    <Input variant="bordered" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                    <Input variant="bordered" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                    <Input variant="bordered" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <Input variant="bordered" placeholder="Subdomain" onChange={(e) => setSubdomain(e.target.value)} value={subdomain} />
                    <Input variant="bordered" placeholder="Favicon URL" onChange={(e) => setFaviconUrl(e.target.value)} value={faviconUrl} />
                    <Input variant="bordered" placeholder="Primary Color" onChange={(e) => setPrimaryColor(e.target.value)} value={primaryColor} />
                    <Button
                        color="primary"
                        style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
                        onPress={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit'}
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}