import { Navbar, NavbarContent, NavbarItem, Link, NavbarMenuItem, NavbarMenuToggle, NavbarMenu, Avatar } from "@nextui-org/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../services/firebase";
import { signOut } from 'firebase/auth';

export default function Navigation({ userData }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    const menuItems = [
        { name: "Dashboard", action: handleDashboard },
        { name: "Log Out", action: handleLogout }, // Add an action to handle logout
    ];


    if (userData?.type === 'brand_admin') {
        menuItems.splice(1, 0, { name: "Brand Admin Dashboard", action: () => navigate('/brand-admin-dashboard') });
    }

    if (userData?.type === 'organization_admin' || userData?.type === 'brand_admin') {
        menuItems.splice(1, 0, { name: "Teams", action: () => navigate('/org-admin-dashboard/teams') });
    }

    if (userData?.type === 'organization_admin' || userData?.type === 'brand_admin') {
        menuItems.splice(2, 0, { name: "Templates", action: () => navigate('/org-admin-dashboard/templates') });
    }

    if (userData?.type === 'organization_admin' || userData?.type === 'brand_admin') {
        menuItems.splice(3, 0, { name: "Recipients", action: () => navigate('/org-admin-dashboard/recipients') });
    }

    if (userData?.type === 'organization_admin' || userData?.type === 'brand_admin') {
        menuItems.splice(4, 0, { name: "Groups", action: () => navigate('/org-admin-dashboard/groups') });
    }

    if (userData?.type === 'organization_admin' || userData?.type === 'brand_admin') {
        menuItems.splice(4, 0, { name: "Tests", action: () => navigate('/org-admin-dashboard/tests') });
    }

    // Function to handle the logout process
    function handleLogout() {
        console.log('Logging out...'); // Add this line for debugging
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/login'); // Redirect to /login after logout
        }).catch((error) => {
            // An error happened.
            console.error("Logout error:", error);
        });
    }

    function handleDashboard() {
        navigate('/org-admin-dashboard');
    }

    return (
        <Navbar onMenuOpenChange={setIsMenuOpen} maxWidth='full'>
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    <Avatar name={userData?.first_name[0] + userData?.last_name[0]} size="large" />
                </NavbarItem>
            </NavbarContent>
            <NavbarMenu>
                {menuItems.map((menuItem, index) => (
                    <NavbarMenuItem key={`${menuItem.name}-${index}`}>
                        <Link
                            color={
                                index === menuItems.length - 1 ? "danger" : "foreground"
                            }
                            className="w-full"
                            href="#"
                            size="lg"
                            onClick={menuItem.action}
                        >
                            {menuItem.name}
                        </Link>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
    );
}