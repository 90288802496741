// client/src/services/testService.js
const API_URL = process.env.REACT_APP_API_URL;

/**
 * Creates a new test.
 *
 * @param {Object} testData The data for the new test.
 * @returns {Promise<string>} A promise that resolves to the unique identifier of the newly created test.
 */
export const createTest = async (testData) => {
    const response = await fetch(`${API_URL}/tests`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(testData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.text(); // Assuming the response is just the unique identifier as a string
};

/**
 * Retrieves all tests for a specific organization.
 *
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to a list of tests.
 */
export const getAllTestsByOrganization = async (organizationId) => {
    const response = await fetch(`${API_URL}/tests/organization/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON array of tests
};

/**
 * Retrieves an individual test by its unique identifier.
 *
 * @param {string} testId The unique identifier of the test.
 * @returns {Promise<Object>} A promise that resolves to the test data.
 */
export const getIndividualTest = async (testId) => {
    const response = await fetch(`${API_URL}/tests/${testId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON object of the test
};

/**
 * Retrieves the count of messages sent by an organization, grouped by day, 
 * filtered by a specified time range.
 *
 * @param {string} organizationId The unique identifier of the organization.
 * @param {number} days The number of days to look back for messages.
 * @returns {Promise<Array>} A promise that resolves to a list of dictionaries with the date as the key and the count of messages for that date as the value.
 */
export const getTotalMessagesByTimeFilter = async (organizationId, days = 7) => {
    const response = await fetch(`${API_URL}/email-messages/total_messages_by_time_filter/${organizationId}?days=${days}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON array of message counts by day
};

/**
 * Retrieves the count of phished messages sent by an organization, grouped by day, 
 * filtered by a specified time range.
 *
 * @param {string} organizationId The unique identifier of the organization.
 * @param {number} days The number of days to look back for phished messages.
 * @returns {Promise<Array>} A promise that resolves to a list of dictionaries with the date as the key and the count of phished messages for that date as the value.
 */
export const getPhishedMessagesByTimeFilter = async (organizationId, days = 7) => {
    const response = await fetch(`${API_URL}/email-messages/phished_messages_by_time_filter/${organizationId}?days=${days}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON array of phished message counts by day
};