import { Button, Code, Input } from "@nextui-org/react";
import { Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { createRecipient } from "../../services/recipientServices";

export default function SingleRecipient({ organizationId }) {
    const [formData, setFormData] = useState({
        organization_id: organizationId,
        first_name: '',
        last_name: '',
        email: '',
        title: ''
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.first_name || !formData.last_name || !formData.email) {
            console.error('Please fill in all required fields.');
            setError('Please fill in all required fields.');
            return; // Exit early if required fields are missing
        }
        try {
            const response = await createRecipient(formData);
            console.log('Recipient created successfully', response);
            // Handle success, e.g., show a success message or clear the form
            setFormData({ first_name: '', last_name: '', email: '', title: '' }); // Clear the form
        } catch (error) {
            console.error('Failed to create recipient:', error);
            setError('Failed to create recipient.');
            // Handle error, e.g., show an error message
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Code color="danger" style={{ marginBottom: '10px' }}>{error}</Code>}
            <Row>
                <Col>
                    <Form.Group style={{ marginBottom: '10px' }}>
                        <Input
                            width="100%"
                            variant="bordered"
                            type="text"
                            placeholder="First name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group style={{ marginBottom: '10px' }}>
                        <Input
                            width="100%"
                            variant="bordered"
                            type="text"
                            placeholder="Last name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group style={{ marginBottom: '10px' }}>
                <Input
                    width="100%"
                    variant="bordered"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group style={{ marginBottom: '10px' }}>
                <Input
                    width="100%"
                    variant="bordered"
                    type="text"
                    placeholder="Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                />
            </Form.Group>
            <Button
                color="primary"
                style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
                type="submit"
            >
                Create Recipient
            </Button>
        </Form>
    );
}