import { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { getRecentEmails, getPhishedEmails } from "../services/organizationService";
import { LineChart } from '@mui/x-charts';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import EmailBarChart from "../components/EmailBarChart";
import PhishedEmailBarChart from "../components/PhishedEmailBarChart";
import { useNavigate } from "react-router-dom";


export default function OrgAdminDashboard({ userData, orgInformation }) {
    const [recentEmailCount, setRecentEmailCount] = useState(0);
    const [phishedEmailCount, setPhishedEmailCount] = useState(0);
    const [phishedEmailData, setPhishedEmailData] = useState([]);
    const [timeScope, setTimeScope] = useState("7");
    const [phishedTimeScope, setPhishedTimeScope] = useState("7");
    const navigate = useNavigate(); // Initialize the navigate function

    const handleCreateTestClick = () => {
        navigate('/org-admin-dashboard/create-test'); // Use navigate to change the route
    };

    useEffect(() => {
        if (orgInformation?._id) {
            fetchOrganizationData(orgInformation._id);
        }
    }, [orgInformation]);

    const fetchOrganizationData = async (organizationId) => {
        try {
            const recentEmailsData = await getRecentEmails(organizationId);
            setRecentEmailCount(recentEmailsData.length);
            const phishedEmailsData = await getPhishedEmails(organizationId);
            console.log('Phished Emails Data:', phishedEmailsData); // Debugging log
            setPhishedEmailCount(phishedEmailsData.length);
            setPhishedEmailData(phishedEmailsData);
        } catch (error) {
            console.error('Error fetching organization data:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleTimeScopeChange = (key) => {
        setTimeScope(key);
    };

    const handlePhishedTimeScopeChange = (key) => {
        setPhishedTimeScope(key);
    };

    const buttonColor = `${process.env.REACT_APP_PRIMARY_COLOR}`

    return (
        <div>
            <Navigation userData={userData} />
            <Button
                style={{ marginLeft: '25px' }}
                color={buttonColor}
                onClick={handleCreateTestClick}
            >Create a Test</Button> {/* Add this line for the CTA button */}
            {orgInformation?._id && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                    <div style={{ width: '35%', marginLeft: '25px' }}>
                        <Dropdown>
                            <DropdownTrigger>
                                <Button auto flat color="secondary" css={{ tt: "capitalize" }}>
                                    {`Time Scope: ${timeScope} days`}
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Time Scope" onAction={handleTimeScopeChange}>
                                <DropdownItem key="1">1 day</DropdownItem>
                                <DropdownItem key="5">5 days</DropdownItem>
                                <DropdownItem key="7">7 days</DropdownItem>
                                <DropdownItem key="14">2 weeks</DropdownItem>
                                <DropdownItem key="30">1 month</DropdownItem>
                                <DropdownItem key="90">3 months</DropdownItem>
                                <DropdownItem key="180">6 months</DropdownItem>
                                <DropdownItem key="365">1 year</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <EmailBarChart organizationId={orgInformation._id} timeScope={timeScope} />
                    </div>

                    <div style={{ width: '35%', marginRight: '25px' }}>
                        <Dropdown>
                            <DropdownTrigger>
                                <Button auto flat color="secondary" css={{ tt: "capitalize" }}>
                                    {`Time Scope: ${phishedTimeScope} days`}
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Time Scope" onAction={handlePhishedTimeScopeChange}>
                                <DropdownItem key="1">1 day</DropdownItem>
                                <DropdownItem key="5">5 days</DropdownItem>
                                <DropdownItem key="7">7 days</DropdownItem>
                                <DropdownItem key="14">2 weeks</DropdownItem>
                                <DropdownItem key="30">1 month</DropdownItem>
                                <DropdownItem key="90">3 months</DropdownItem>
                                <DropdownItem key="180">6 months</DropdownItem>
                                <DropdownItem key="365">1 year</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <PhishedEmailBarChart organizationId={orgInformation._id} timeScope={phishedTimeScope} />
                    </div>
                </div>
            )}
        </div>
    );
}