import React, { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableColumn, TableHeader, TableRow, TableCell, Chip, useDisclosure } from "@nextui-org/react";
import Navigation from "../components/Navigation";
import { getOrganizationUsers } from '../services/organizationService';
import InviteMemberModal from '../components/InviteMemberModal';

export default function Team({ userData, orgInformation }) {
    const [users, setUsers] = useState([]);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    useEffect(() => {
        if (userData && orgInformation) {
            getOrganizationUsers(orgInformation?._id)
                .then(setUsers)
                .catch(error => {
                    console.error('Error fetching organization users:', error);
                    // Handle the error state appropriately
                });
        }
    }, [userData]);

    const handleOpenInviteMemberModal = () => {
        onOpen();
    }

    return (
        <div>
            <Navigation userData={userData} />
            <InviteMemberModal isOpen={isOpen} onOpenChange={onOpenChange} userData={userData} refreshUserData={() => { }} organizationId={orgInformation?._id} />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <Button
                    style={{ marginTop: '1rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={handleOpenInviteMemberModal}
                >
                    Invite Member
                </Button>
                <Table removeWrapper>
                    <TableHeader>
                        <TableColumn>First Name</TableColumn>
                        <TableColumn>Last Name</TableColumn>
                        <TableColumn>Email</TableColumn>
                        <TableColumn></TableColumn>
                        <TableColumn>Status</TableColumn>
                    </TableHeader>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{user.first_name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    {userData.email === user.email && <Chip style={{ backgroundColor: '#d2f4d3', color: '#1a7f64' }}>You</Chip>}
                                </TableCell>
                                <TableCell>
                                    {user.invited ? (
                                        <Chip color="warning">Invite pending</Chip>
                                    ) : (
                                        <Chip style={{ backgroundColor: '#d2f4d3', color: '#1a7f64' }}>Active</Chip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}