const API_URL = process.env.REACT_APP_API_URL;


/**
 * Fetches all users associated with a given organization ID.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Object[]>} A promise that resolves to the list of users.
 */
async function getOrganizationUsers(organizationId) {
    return fetch(`${API_URL}/organizations/${organizationId}/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching organization users failed:', error);
            throw error;
        });
}

/**
 * Fetches details of a specific organization by its ID.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Object>} A promise that resolves to the organization details.
 */
async function getOrganizationDetails(organizationId) {
    return fetch(`${API_URL}/organizations/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching organization details failed:', error);
            throw error;
        });
}

/**
 * Fetches all recent email messages sent by an organization within the last day.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Object[]>} A promise that resolves to the list of recent email messages.
 */
async function getRecentEmails(organizationId) {
    return fetch(`${API_URL}/organizations/${organizationId}/emails/recent`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching recent emails failed:', error);
            throw error;
        });
}

/**
 * Fetches all email messages sent by an organization within the last day where 'phished' is true.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Object[]>} A promise that resolves to the list of phished email messages.
 */
async function getPhishedEmails(organizationId) {
    return fetch(`${API_URL}/organizations/${organizationId}/emails/phished`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching phished emails failed:', error);
            throw error;
        });
}

/**
 * Initiates the creation of a new EC2 instance with the specified parameters.
 * 
 * @param {Object} instanceData The data for the EC2 instance creation including instance name, subdomain, favicon URL, and primary color.
 * @returns {Promise<string>} A promise that resolves to the message indicating the initiation of EC2 instance creation.
 */
async function createEC2Instance(instanceData) {
    return fetch(`${API_URL}/create-ec2-instance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(instanceData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text(); // Assuming the response is a plain text message
        })
        .catch(error => {
            console.error('Creating EC2 instance failed:', error);
            throw error;
        });
}

export {
    getOrganizationUsers,
    getOrganizationDetails,
    getRecentEmails,
    getPhishedEmails,
    createEC2Instance // Export the new function
};