// src/services/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAasOnnOYEKx-uUqL_2dcdXYb6z5pOmJ4w",
    authDomain: "prg-phishing-257ea.firebaseapp.com",
    projectId: "prg-phishing-257ea",
    storageBucket: "prg-phishing-257ea.appspot.com",
    messagingSenderId: "641094682390",
    appId: "1:641094682390:web:861213e94c11c192a0f237",
    measurementId: "G-PDQ9YTTDQ8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };