// client/src/pages/Groups.js
import { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import { Button, Spinner, useDisclosure } from "@nextui-org/react";
import { Table, TableHeader, TableBody, TableColumn, TableRow, TableCell } from "@nextui-org/react";
import CreateGroupModal from "../components/CreateGroupModal"; // Make sure this import is correct
import { getGroupsWithRecipientsByOrganization } from "../services/groupService";

export default function Groups({ userData, orgInformation }) {
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (userData && orgInformation) {
            fetchGroups();
        }
    }, [userData]);

    const fetchGroups = async () => {
        setLoading(true);
        try {
            const data = await getGroupsWithRecipientsByOrganization(orgInformation?._id);
            setGroups(data);
        } catch (error) {
            console.error('Error fetching groups:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner />
            </div>
        );
    }

    return (
        <div>
            <Navigation userData={userData} />
            <CreateGroupModal
                isOpen={isOpen}
                onOpenChange={onClose}
                userData={userData}
                fetchGroups={fetchGroups} // Pass fetchGroups to refresh the list after creating a group
                orgInformation={orgInformation}
            />
            <div style={{ padding: '1.5rem' }}>
                <Button
                    style={{ marginTop: '0.25rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={onOpen} // Use the onOpen function from useDisclosure
                >
                    Create Group
                </Button>
                {groups.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                        No groups found.
                    </div>
                ) : (
                    <Table
                        aria-label="Groups"
                    >
                        <TableHeader>
                            <TableColumn>Group Name</TableColumn>
                            <TableColumn>Number of Recipients</TableColumn>
                            <TableColumn>Updated By</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {groups.map((group, index) => (
                                <TableRow key={`group-${index}`}>
                                    <TableCell>{group.name}</TableCell>
                                    <TableCell>{group.recipients.length}</TableCell>
                                    <TableCell>{group.updated_by}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
    );
}