import { Input, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { getAllRecipientsForOrganization } from "../../services/recipientServices";
import { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types';
import { SearchIcon } from "./SearchIcon";

export default function GroupSelect({ organizationId, onSelectionChange }) {
    const [page, setPage] = useState(1);
    const [recipients, setRecipients] = useState([]);
    const [filteredRecipients, setFilteredRecipients] = useState([]); // State for filtered recipients
    const [selected, setSelected] = useState(new Set()); // Use Set for selected rows
    const [filterValue, setFilterValue] = useState(''); // State for filter input value
    const pageSize = 4;

    useEffect(() => {
        fetchRecipients();
    }, [organizationId]);

    useEffect(() => {
        // Filter recipients whenever the filterValue changes
        const filtered = recipients.filter(recipient => {
            return recipient.email.toLowerCase().includes(filterValue.toLowerCase());
        });
        setFilteredRecipients(filtered);
    }, [filterValue, recipients]);

    const fetchRecipients = async () => {
        try {
            const data = await getAllRecipientsForOrganization(organizationId);
            setRecipients(data);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    const handleSelectionChange = (selectedKeys) => {
        const newSelection = new Set(selectedKeys);
        setSelected(newSelection);
        if (onSelectionChange) {
            onSelectionChange(Array.from(newSelection)); // Pass the array of selected IDs
        }
        console.log('Selected:', Array.from(newSelection));
    };

    const totalPages = useMemo(() => {
        return Math.ceil(filteredRecipients.length / pageSize);
    }, [filteredRecipients.length, pageSize]);

    return (
        <Table
            selectionMode="multiple"
            onSelectionChange={handleSelectionChange}
            aria-label="Select recipients"
            color="default"
            topContent={
                <Input
                    isClearable
                    variant="bordered"
                    placeholder="Search by email..."
                    startContent={<SearchIcon />}
                    value={filterValue}
                    onClear={() => setFilterValue('')}
                    onValueChange={(value) => setFilterValue(value)}
                />
            }
            topContentPlacement="inside"
            bottomContent={
                page > 0 && (
                    <div className="flex w-full justify-center">
                        <Pagination
                            isCompact
                            showControls
                            showShadow
                            color="primary"
                            page={page}
                            total={totalPages}
                            onChange={setPage}
                        />
                    </div>
                )
            }
        >
            <TableHeader>
                <TableColumn>Recipient first name</TableColumn>
                <TableColumn>Recipient last name</TableColumn>
                <TableColumn>Title</TableColumn>
                <TableColumn>Email</TableColumn>
            </TableHeader>
            <TableBody>
                {filteredRecipients.slice((page - 1) * pageSize, page * pageSize).map((recipient) => (
                    <TableRow key={recipient._id} selected={selected.has(recipient._id)}>
                        {/* Use the _id field to manage selection */}
                        <TableCell>{recipient.first_name}</TableCell>
                        <TableCell>{recipient.last_name}</TableCell>
                        <TableCell>{recipient.title}</TableCell>
                        <TableCell>{recipient.email}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

GroupSelect.propTypes = {
    organizationId: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func, // Add this line
};