// client/src/services/userServices.js

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Creates a new recipient in the system.
 * 
 * @param {Object} recipientData The data of the recipient to create.
 * @returns {Promise<Object>} A promise that resolves to the newly created recipient's details.
 */
async function createRecipient(recipientData) {
    return fetch(`${API_URL}/recipients/recipient`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipientData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error creating recipient:', error);
            throw error;
        });
}

/**
 * Creates multiple recipients in the system in bulk.
 * 
 * @param {Array} recipientsData Array of recipient data objects to create.
 * @returns {Promise<Array>} A promise that resolves to an array of the newly created recipients' details.
 */
async function createBulkRecipients(recipientsData) {
    return fetch(`${API_URL}/recipients/recipients/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipientsData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error creating recipients in bulk:', error);
            throw error;
        });
}


/**
 * Retrieves all recipients for a specific organization.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Array>} A promise that resolves to an array of recipients.
 */
async function getAllRecipientsForOrganization(organizationId) {
    return fetch(`${API_URL}/recipients/organization/${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error retrieving recipients:', error);
            throw error;
        });
}

/**
 * Retrieves a single recipient by their unique identifier.
 * 
 * @param {string} recipientId The unique identifier of the recipient.
 * @returns {Promise<Object>} A promise that resolves to the recipient's details.
 */
async function getSingleRecipient(recipientId) {
    return fetch(`${API_URL}/recipients/${recipientId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error retrieving recipient:', error);
            throw error;
        });
}

/**
 * Updates a single recipient in the system.
 * 
 * @param {string} recipientId The unique identifier of the recipient to update.
 * @param {Object} recipientData The updated data of the recipient.
 * @returns {Promise<boolean>} A promise that resolves to true if the update was successful.
 */
async function updateSingleRecipient(recipientId, recipientData) {
    return fetch(`${API_URL}/recipients/${recipientId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipientData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error updating recipient:', error);
            throw error;
        });
}

/**
 * Deletes a single recipient from the system.
 * 
 * @param {string} recipientId The unique identifier of the recipient to delete.
 * @returns {Promise<boolean>} A promise that resolves to true if the deletion was successful.
 */
async function deleteSingleRecipient(recipientId) {
    return fetch(`${API_URL}/recipients/${recipientId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error deleting recipient:', error);
            throw error;
        });
}

/**
 * Deletes multiple recipients from the system in bulk.
 * 
 * @param {Array} recipientIds Array of unique identifiers for the recipients to delete.
 * @returns {Promise<boolean>} A promise that resolves to true if the bulk deletion was successful.
 */
async function deleteMultipleRecipients(recipientIds) {
    return fetch(`${API_URL}/recipients/bulk`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipientIds)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error deleting recipients in bulk:', error);
            throw error;
        });
}

/**
 * Retrieves multiple recipients by their unique identifiers.
 * 
 * @param {Array} recipientIds Array of unique identifiers for the recipients.
 * @returns {Promise<Array>} A promise that resolves to an array of recipients' details.
 */
async function getMultipleRecipients(recipientIds) {
    return fetch(`${API_URL}/recipients/recipients`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(recipientIds)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error retrieving multiple recipients:', error);
            throw error;
        });
}

export {
    createRecipient,
    createBulkRecipients,
    getAllRecipientsForOrganization,
    getSingleRecipient,
    updateSingleRecipient,
    deleteSingleRecipient,
    deleteMultipleRecipients,
    getMultipleRecipients,
};