// client/src/pages/Tests.js
import { Button, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import Navigation from "../components/Navigation";
import { useEffect, useState } from "react";
import { getAllTestsByOrganization } from "../services/testService";
import { useNavigate } from "react-router-dom";

export default function Tests({ userData, orgInformation }) {
    const [loading, setLoading] = useState(true);
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (userData && orgInformation) {
            fetchTests();
        }
    }, [userData]);

    const fetchTests = async () => {
        setLoading(true);
        try {
            const data = await getAllTestsByOrganization(orgInformation?._id);
            setTests(data);
        } catch (error) {
            console.error('Error fetching tests:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner />
            </div>
        );
    }

    return (
        <div>
            <Navigation userData={userData} />
            <div style={{ padding: '1.5rem' }}>
                <Button
                    style={{ marginTop: '0.25rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={() => navigate('/org-admin-dashboard/create-test')}
                >
                    Create Test
                </Button>
                {tests.length === 0 ? (
                    <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                        No tests found.
                    </div>
                ) : (
                    <Table
                        aria-label="Tests"
                    >
                        <TableHeader>
                            <TableColumn>Test Name</TableColumn>
                            <TableColumn>Number of Recipients</TableColumn>
                            <TableColumn>Updated By</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {tests.map((test, index) => (
                                <TableRow key={`test-${index}`}>
                                    <TableCell>{test.name}</TableCell>
                                    <TableCell>{test.recipients.length}</TableCell>
                                    <TableCell>{test.created_by}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        </div>
    );
}