import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/system';
import { AcceptInvitationGuard } from './guards/AcceptInvitationGuard';
import { BrandAdminDashboardGuard } from './guards/BrandAdminDashboardGuard';
import Login from './pages/Login';
import { OrgAdminDashboardGuard } from './guards/OrgAdminDashboardGuard';

function App() {
  return (
    <NextUIProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<h1>Hello world</h1>} />
            <Route path="/login" element={<Login />} />
            <Route path="/bad-invitation" element={<h1>Bad invitation :(</h1>} />
            <Route path="/brand-admin-dashboard" element={<BrandAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/teams" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/templates" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/create-template" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/recipients" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/groups" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/tests" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/create-test" element={<OrgAdminDashboardGuard />} />
            <Route path="/org-admin-dashboard/template/:templateId" element={<OrgAdminDashboardGuard />} />
            <Route path="/accept-invite/:userId" element={<AcceptInvitationGuard />} />
          </Routes>
        </Router>
      </div>
    </NextUIProvider>
  );
}

export default App;
