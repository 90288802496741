// client/src/pages/Recipients.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import { Button, Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Spinner, useDisclosure } from "@nextui-org/react";
import CreateRecipientModal from "../components/CreateRecipientModal";
import { getAllRecipientsForOrganization } from "../services/recipientServices"; // Import the service function

export default function Recipient({ userData, orgInformation }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Add loading state
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(1);
    const [recipients, setRecipients] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (userData && orgInformation) {
            fetchRecipients();
        }
    }, [userData]);

    const fetchRecipients = async () => {
        setLoading(true); // Start loading
        try {
            const data = await getAllRecipientsForOrganization(orgInformation?._id);
            setRecipients(data);
            setTotal(data.length);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner />
            </div>
        );
    }

    // Calculate the current set of recipients to display
    const indexOfLastRecipient = page * pageSize;
    const indexOfFirstRecipient = indexOfLastRecipient - pageSize;
    const currentRecipients = recipients.slice(indexOfFirstRecipient, indexOfLastRecipient);

    return (
        <div>
            <Navigation userData={userData} />
            <CreateRecipientModal
                isOpen={isOpen}
                onOpenChange={onClose}
                userData={userData}
                fetchRecipients={fetchRecipients} // Pass fetchRecipients to refresh the list after creating a recipient
                orgInformation={orgInformation}
            />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <Button
                    style={{ marginTop: '1rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={onOpen}
                >
                    Create Recipient
                </Button>
                {recipients.length === 0 ? null : (
                    <Table
                        selectionMode='single'
                        bottomContent={
                            total > pageSize ? ( // Only show pagination if there are more items than pageSize
                                <div className="flex w-full justify-center">
                                    <Pagination
                                        isCompact
                                        showControls
                                        showShadow
                                        color="primary"
                                        page={page}
                                        total={Math.ceil(total / pageSize)}
                                        onChange={(newPage) => setPage(newPage)} // Make sure to use a different name for the parameter to avoid shadowing the page state
                                    />
                                </div>
                            ) : null
                        }
                    >
                        <TableHeader>
                            <TableColumn>Recipient first name</TableColumn>
                            <TableColumn>Recipient last name</TableColumn>
                            <TableColumn>Title</TableColumn>
                            <TableColumn>Email</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {currentRecipients.map((recipient) => ( // Use currentRecipients instead of recipients
                                <TableRow key={recipient._id} onClick={() => navigate(`/org-admin-dashboard/recipient/${recipient._id}`)} >
                                    <TableCell>{recipient.first_name}</TableCell>
                                    <TableCell>{recipient.last_name}</TableCell>
                                    <TableCell>{recipient.title}</TableCell>
                                    <TableCell>{recipient.email}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
            {recipients.length === 0 ? <div style={{ textAlign: 'center', marginTop: '8rem' }}>
                No recipients found. Click "Create Recipient" to add new ones.
            </div> : null}
        </div>
    )
}