import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from "../components/Navigation";
import { getTemplateById, updateTemplateById } from '../services/templateService';
import ReactQuill from 'react-quill';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { FullPage } from '@ckeditor/ckeditor5-html-support';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import { EditIcon } from './CreateTemplate';
import { ModalTitle } from 'react-bootstrap';

export default function IndividualTemplate({ userData, orgInformation }) {
    const { templateId } = useParams();
    const [template, setTemplate] = useState(null);
    const [tempTitle, setTempTitle] = useState(''); // Temporary state for the input
    const [value, setValue] = useState(''); // Initialize with empty string
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const data = await getTemplateById(templateId);
                setTemplate(data);
                setValue(data.content); // Initialize the editor with the fetched content
                setTempTitle(data.name); // Initialize the temporary title
            } catch (error) {
                console.error('Failed to fetch template:', error);
                setError(error); // Set error if fetching fails
            }
        };

        if (templateId) {
            fetchTemplate();
        }
    }, [templateId]);

    const handleSaveTemplateTitle = async () => {
        setIsLoading(true);
        try {
            setTemplate({ ...template, name: tempTitle }); // Update the local state
            onClose(); // Close the modal
        } catch (error) {
            console.error('Failed to update template title:', error);
            setError(error); // Set error if updating fails
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            // Update the template with both the new title and content
            await updateTemplateById(templateId, { name: tempTitle, content: value });
            // Update the local state with the new title and content
            setTemplate({ ...template, name: tempTitle, content: value });
            setIsLoading(false);
            navigate('/org-admin-dashboard/templates'); // Redirect after successful update
        } catch (error) {
            console.error('Failed to update template:', error);
            setError(error); // Set error if updating fails
            setIsLoading(false);
        }
    };

    const handleEditIconClick = () => {
        onOpen();
    };

    return (
        <div>
            <Navigation userData={userData} />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                >
                    {template?.name} <span style={{ display: 'inline-block' }}><EditIcon onClick={handleEditIconClick} /></span>
                </h1>
            </div>
            <Modal isOpen={isOpen} onClose={onClose} placement="top-center">
                <ModalContent>
                    <ModalHeader>
                        <ModalTitle>Edit Template Title</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            value={tempTitle} // Use the temporary title for the input
                            onChange={(e) => setTempTitle(e.target.value)} // Update the temporary title as the user types
                            variant="bordered"
                            style={{ width: '100%' }}
                        />
                        <Button
                            color="primary"
                            onClick={handleSaveTemplateTitle} // Save the temporary title to the actual title
                            style={{
                                marginBottom: '1rem',
                            }}
                        >Save</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                {error && <Code style={{ marginBottom: '1rem' }} color="danger">{error.message}</Code>}
                {/* <CKEditor
                    editor={ClassicEditor}
                    // config={{
                    //     plugins: [FullPage],
                    // }}
                    data={value}
                    onReady={editor => {
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue(data);
                    }}
                    style={{
                        minHeight: '400px',
                        backgroundColor: 'white',
                        marginBottom: '4rem',
                    }}
                /> */}
                <textarea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    style={{
                        minHeight: '400px',
                        width: '100%',
                        backgroundColor: 'white',
                        marginBottom: '4rem',
                    }}
                />
                <div style={{ display: 'flex' }}>
                    <Button color="primary" style={{ marginRight: '10px' }} onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Submit'}
                    </Button>
                    <Button color="default" flat onClick={() => navigate('/org-admin-dashboard/templates')}>Cancel</Button>
                </div>
            </div>
        </div>
    );
}