import { useState } from "react";
import Navigation from "../components/Navigation";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { FullPage }  from '@ckeditor/ckeditor5-html-support';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { Form, ModalTitle } from "react-bootstrap";
import { createTemplate, extractHtmlFromEml } from "../services/templateService";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import parse from 'html-react-parser'; // Import html-react-parser


export const EditIcon = ({ onClick }) => (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }}>
        <path d="M2.99878 17.4612V20.5012C2.99878 20.7812 3.21878 21.0012 3.49878 21.0012H6.53878C6.66878 21.0012 6.79878 20.9512 6.88878 20.8512L17.8088 9.94125L14.0588 6.19125L3.14878 17.1012C3.04878 17.2012 2.99878 17.3212 2.99878 17.4612ZM20.7088 7.04125C21.0988 6.65125 21.0988 6.02125 20.7088 5.63125L18.3688 3.29125C17.9788 2.90125 17.3488 2.90125 16.9588 3.29125L15.1288 5.12125L18.8788 8.87125L20.7088 7.04125Z" fill="black" />
    </svg>
);

export default function CreateTemplate({ userData, orgInformation }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure();
    const [value, setValue] = useState('');
    const [templateTitle, setTemplateTitle] = useState('New template');
    const [tempTitle, setTempTitle] = useState(templateTitle); // Temporary state for the input
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null); // Add error state
    const navigate = useNavigate();

    const handleEditIconClick = () => {
        setTempTitle(templateTitle); // Set the temporary title to the current title when opening the modal
        onOpen();
    };

    const handleSaveTemplateTitle = () => {
        setTemplateTitle(tempTitle); // Update the actual title when saving
        onClose();
    };

    const handleSubmit = async () => {
        setIsLoading(true); // Start loading
        try {
            const templateId = await createTemplate({
                name: templateTitle,
                content: value,
                updated_by: userData._id,
                organization_id: orgInformation?._id,
                updated_date: new Date().toISOString(),
            });
            navigate(`/org-admin-dashboard/templates`)
            // Handle the success, e.g., navigate to a new page or show a success message
        } catch (error) {
            console.error('Failed to create template:', error);
            setError(error);
            // Handle the error, e.g., show an error message
        } finally {
            setIsLoading(false); // Stop loading regardless of the outcome
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);
            try {
                const response = await extractHtmlFromEml(file); // Use the extractHtmlFromEml function
                setValue(response.html_content); // Set the editor's content to the extracted HTML
                console.log('File uploaded and content extracted successfully');
            } catch (error) {
                console.error('Error uploading file:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div>
            <Navigation userData={userData} />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                >
                    {templateTitle} <span style={{ display: 'inline-block' }}><EditIcon onClick={handleEditIconClick} /></span>
                </h1>
            </div>
            <div style={{ width: '35%', marginBottom: '5px', marginLeft: '25px', display: 'flex', justifyContent: 'space-between' }}>
                <Form.Control type="file" onChange={handleFileUpload} />
                <Button color="secondary" onClick={onPreviewOpen} style={{ marginLeft: '10px' }} isDisabled={value.length === 0}>Preview</Button>
            </div>
            <Modal isOpen={isOpen} onClose={onClose} placement="top-center">
                <ModalContent>
                    <ModalHeader>
                        <ModalTitle>Edit Template Title</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            value={tempTitle} // Use the temporary title for the input
                            onChange={(e) => setTempTitle(e.target.value)} // Update the temporary title as the user types
                            variant="bordered"
                            style={{ width: '100%' }}
                        />
                        <Button
                            color="primary"
                            onClick={handleSaveTemplateTitle} // Save the temporary title to the actual title
                            style={{
                                marginBottom: '1rem',
                            }}
                        >Save</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                {error && <Code style={{ marginBottom: '1rem' }} color="danger">{error.message}</Code>}
                <textarea
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    style={{
                        minHeight: '400px',
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                    }}
                />
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <Button color="primary" style={{ marginRight: '10px' }} onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Submit'}
                    </Button>
                    <Button color="default" flat onClick={() => navigate('/org-admin-dashboard/templates')}>Cancel</Button>
                </div>
            </div>
            <Modal
                isOpen={isPreviewOpen}
                onClose={onPreviewClose}
                placement="top-center"
                style={{
                    minHeight: '200px',
                    maxHeight: '500px',
                    minWidth: '400px',
                    maxWidth: '800px',
                }}
                scrollBehavior="inside"
            >
                <ModalContent>
                    <ModalHeader>
                        <ModalTitle>Template Preview</ModalTitle>
                    </ModalHeader>
                    <ModalBody style={{ minHeight: '400px', maxHeight: '800px' }}> {/* Set a fixed height for the modal body */}
                        <Tabs aria-label="Preview Tabs">
                            <Tab title="Markdown">
                                <ReactMarkdown>{value}</ReactMarkdown>
                            </Tab>
                            <Tab title="HTML">
                                <div style={{ minHeight: '100%', overflow: 'auto' }}>
                                    <iframe
                                        srcDoc={value}
                                        style={{
                                            width: '100%',
                                            height: '500px',
                                            border: 'none',
                                        }}
                                        title="HTML Preview"
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}