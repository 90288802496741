// client/src/components/CreateOrganizationModal.js
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";
import { useState } from "react";
import { inviteOrganizationAdmin } from "../services/userServices"; // Import the service function

export default function InviteMemberModal({ isOpen, onOpenChange, userData, refreshUserData, organizationId }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(''); // Add an error state if necessary
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (loading) return;

        setLoading(true);
        setError('');

        if (!firstName || !lastName || !email) {
            setError('All fields are required');
            setLoading(false);
            return;
        }

        console.log(userData)

        // Construct the invitation data object
        const inviteData = {
            organization_id: organizationId,
            first_name: firstName,
            last_name: lastName,
            email: email
        };

        try {
            // Call the service function to send the invitation
            const response = await inviteOrganizationAdmin(inviteData);
            console.log('Organization admin invited successfully:', response);
            await refreshUserData();
            // Handle the successful invitation (e.g., show a message, close the modal)
            onOpenChange(false);
        } catch (error) {
            // Handle errors (e.g., show an error message)
            console.error('Error inviting organization admin:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            placement="top-center"
        >
            <ModalContent>
                <ModalHeader>Invite Member</ModalHeader>
                <ModalBody>
                    {error && <Code color="danger">{error}</Code>}
                    <Input variant="bordered" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                    <Input variant="bordered" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                    <Input variant="bordered" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                    <Button
                        color="primary"
                        style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
                        onPress={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit'}</Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}