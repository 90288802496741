import { Card, CardBody, Modal, ModalBody, ModalContent, ModalHeader, Tab, Tabs } from "@nextui-org/react";
import SingleRecipient from "./createRecipientTabs/SingleRecipient";
import CsvUpload from "./createRecipientTabs/CsvUpload";

export default function CreateRecipientModal({ isOpen, onOpenChange, userData, orgInformation }) {
    let tabs = [
        {
            id: "recipientForm",
            label: "Single Recipient",
            content: <SingleRecipient organizationId={orgInformation?._id} />
        },
        {
            id: "recipientUpload",
            label: "CSV Upload",
            content: <CsvUpload organizationId={orgInformation?._id} />
        }
    ];

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            placement="top-center"
            style={{
                minWidth: '800px',
                minHeight: '400px'
            }}
        >
            <ModalContent>
                <ModalHeader>Add Recipients</ModalHeader>
                <ModalBody>
                    <Tabs items={tabs}>
                        {(item) => (
                            <Tab key={item.id} title={item.label}>
                                <p>
                                    {item.content}
                                </p>
                            </Tab>
                        )}
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}