// client/src/services/templateService.js
const API_URL = process.env.REACT_APP_API_URL;

/**
 * Creates a new template using the provided template data.
 * 
 * @param {Object} templateData The data for the new template.
 * @returns {Promise<string>} A promise that resolves to the unique identifier of the newly created template.
 */
const createTemplate = async (templateData) => {
    const response = await fetch(`${API_URL}/templates/create-template`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(templateData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.text(); // Assuming the response is just the unique identifier as a string
};

/**
 * Retrieves templates for a specific organization with pagination.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @param {number} page The page number to retrieve.
 * @param {number} pageSize The number of items to display per page.
 * @returns {Promise<Array>} A promise that resolves to a list of Template objects.
 */
const getTemplatesByOrganization = async (organizationId, page = 1, pageSize = 10) => {
    const response = await fetch(`${API_URL}/templates/find/${organizationId}?page=${page}&page-size=${pageSize}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON array of Template objects
};

/**
 * Retrieves a single template by its unique identifier.
 * 
 * @param {string} templateId The unique identifier of the template.
 * @returns {Promise<Object>} A promise that resolves to the Template object.
 */
const getTemplateById = async (templateId) => {
    const response = await fetch(`${API_URL}/templates/template/${templateId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON object for the Template
};

/**
 * Updates an existing template by its unique identifier.
 * 
 * @param {string} templateId The unique identifier of the template to update.
 * @param {Object} templateData The data to update the template with.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 */
const updateTemplateById = async (templateId, templateData) => {
    const response = await fetch(`${API_URL}/templates/template/${templateId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(templateData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON object with a message
};

/**
 * Deletes an existing template by its unique identifier.
 * 
 * @param {string} templateId The unique identifier of the template to delete.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 */
const deleteTemplateById = async (templateId) => {
    const response = await fetch(`${API_URL}/templates/template/${templateId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        // No body is needed for a DELETE request
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON object with a message
};

/**
 * Extracts HTML content from a .eml file.
 * 
 * @param {File} file The .eml file to extract HTML from.
 * @returns {Promise<Object>} A promise that resolves to the HTML content extracted from the .eml file.
 */
const extractHtmlFromEml = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(`${API_URL}/templates/extract-html`, {
        method: 'POST',
        // Headers such as 'Content-Type' are not needed here due to FormData usage
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json(); // Assuming the response is a JSON object with the HTML content
};

export {
    createTemplate,
    getTemplatesByOrganization,
    getTemplateById,
    updateTemplateById,
    deleteTemplateById,
    extractHtmlFromEml, // Export the new function
};