// client/src/guards/BrandAdminDashboardGuard.js

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";
import { getUserByEmail } from "../services/userServices";
import OrgAdminDashboard from "../pages/OrgAdminDashboard"; // Import the dashboard component
import Team from "../pages/Team";
import Templates from "../pages/Templates";
import CreateTemplate from "../pages/CreateTemplate";
import IndividualTemplate from "../pages/InvdividualTemplate";
import { Spinner } from "@nextui-org/react";
import Recipient from "../pages/Recipients";
import Groups from "../pages/Groups";
import Tests from "../pages/Tests";
import CreateTest from "../pages/CreateTest";
import { getOrganizationDetails } from "../services/organizationService";

export const OrgAdminDashboardGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true); // Maintain loading state
    const [userInformation, setUserInformation] = useState(null);
    const [orgInformation, setOrgInformation] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/login');
            } else {
                getUserByEmail(user.email)
                    .then(userData => {
                        if (userData && (userData.type === 'organization_admin' || userData.type === 'brand_admin')) {
                            if (userData.type === 'brand_admin') {
                                const orgId = localStorage.getItem('orgId');
                                if (!orgId) {
                                    navigate('/brand-admin-dashboard');
                                    return;
                                }
                                // Fetch organization details using the orgId
                                getOrganizationDetails(orgId)
                                    .then(orgData => {
                                        setOrgInformation(orgData);
                                    })
                                    .catch(error => {
                                        console.error('Error fetching organization details:', error);
                                        navigate('/login');
                                    });
                            } else {
                                // For organization_admin, assume userData.organizations contains organization details
                                getOrganizationDetails(userData.organizations[0])
                                    .then(orgData => {
                                        setOrgInformation(orgData);
                                    })
                                    .catch(error => {
                                        console.error('Error fetching organization details:', error);
                                        navigate('/login');
                                    });
                            }
                            setUserInformation(userData);
                            setLoading(false);
                        } else {
                            navigate('/login');
                        }
                    })
                    .catch(() => {
                        navigate('/login');
                    })
                    .finally(() => {
                        if (loading) {
                            setLoading(false);
                        }
                    });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, loading]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner />
            </div>
        );
    }

    if (location.pathname === '/org-admin-dashboard') return <OrgAdminDashboard userData={userInformation} setUserInformation={setUserInformation} orgInformation={orgInformation} />; // Render the dashboard if the user is authorized
    else if (location.pathname === '/org-admin-dashboard/teams') return <Team userData={userInformation} orgInformation={orgInformation} />; // Render the dashboard if the user is authorized
    else if (location.pathname.includes('/templates')) return <Templates userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/org-admin-dashboard/template/')) return <IndividualTemplate userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/create-template')) return <CreateTemplate userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/recipients')) return <Recipient userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/groups')) return <Groups userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/tests')) return <Tests userData={userInformation} orgInformation={orgInformation} />;
    else if (location.pathname.includes('/create-test')) return <CreateTest userData={userInformation} orgInformation={orgInformation} />;
};