// client/src/components/selectRecipientsTabs/GroupSelect.js
import { useEffect, useState } from 'react';
import { Select, SelectItem } from "@nextui-org/react";
import { getGroupsWithRecipientsByOrganization } from "../../services/groupService";

export default function GroupSelect({ organizationId, onGroupSelectionChange, onGroupIdsChange }) {
    const [groups, setGroups] = useState([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const groupData = await getGroupsWithRecipientsByOrganization(organizationId);
                setGroups(groupData);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, [organizationId]);

    const handleGroupChange = (groupIds) => {
        setSelectedGroupIds(groupIds);
        const selectedGroups = groups.filter(group => groupIds.includes(group._id));
        const groupRecipients = selectedGroups.flatMap(group => group.recipients.map(recipient => recipient._id));
        onGroupSelectionChange(groupRecipients);
        onGroupIdsChange(groupIds); // Pass the group IDs up
    };

    return (
        <Select
            placeholder="Select groups"
            value={selectedGroupIds}
            onChange={(e) => handleGroupChange(e.target.value)}
            variant="bordered"
        >
            {groups.map((group) => (
                <SelectItem key={group._id} value={group._id}>
                    {group.name}
                </SelectItem>
            ))}
        </Select>
    );
}