import { useEffect, useMemo, useState } from "react";
import Navigation from "../components/Navigation";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, Code, Input, Listbox, ListboxItem, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem, useDisclosure } from "@nextui-org/react";
import { ModalTitle } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createTest } from "../services/testService";
import SelectRecipientsModal from "../components/SelectRecipientsModal";
import { getMultipleRecipients } from "../services/recipientServices";
import { getTemplatesByOrganization } from "../services/templateService";

export const EditIcon = ({ onClick }) => (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ cursor: 'pointer' }}>
        <path d="M2.99878 17.4612V20.5012C2.99878 20.7812 3.21878 21.0012 3.49878 21.0012H6.53878C6.66878 21.0012 6.79878 20.9512 6.88878 20.8512L17.8088 9.94125L14.0588 6.19125L3.14878 17.1012C3.04878 17.2012 2.99878 17.3212 2.99878 17.4612ZM20.7088 7.04125C21.0988 6.65125 21.0988 6.02125 20.7088 5.63125L18.3688 3.29125C17.9788 2.90125 17.3488 2.90125 16.9588 3.29125L15.1288 5.12125L18.8788 8.87125L20.7088 7.04125Z" fill="black" />
    </svg>
);

export const ListboxWrapper = ({ children }) => (
    <div className="w-full max-w-[260px] border-small px-1 py-2 rounded-small border-default-200 dark:border-default-100"
        style={{
            maxWidth: '400px',
        }}
    >
        {children}
    </div>
);

export default function CreateTest({ userData, orgInformation }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: recipientsModalOpen, onOpen: openRecipientsModal, onClose: closeRecipientsModal } = useDisclosure();
    const [value, setValue] = useState('');
    const [testTitle, setTestTitle] = useState('New test');
    const [tempTitle, setTempTitle] = useState(testTitle); // Temporary state for the input
    const [testSubject, setTestSubject] = useState(''); // Add state for the test subject
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null); // Add error state
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [recipientDetails, setRecipientDetails] = useState([]); // Add state for recipient details
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedGroupIds, setSelectedGroupIds] = useState([]); // State to track selected group IDs

    const navigate = useNavigate();

    useEffect(() => {
        fetchRecipientDetails();
    }, [selectedRecipients]);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const data = await getTemplatesByOrganization(orgInformation?._id);
                setTemplates(data.templates);
            } catch (error) {
                console.error('Error fetching templates:', error);
                setError(error);
            }
        };

        if (userData && userData.organizations) {
            fetchTemplates();
        }
    }, [userData]);

    const handleGroupIdsChange = (groupIds) => {
        setSelectedGroupIds(groupIds); // Update the state with the selected group IDs
    };

    const handleTemplateChange = (templateId) => {
        const template = templates.find(t => t._id === templateId);
        if (template) {
            setValue(template.content);
            setSelectedTemplate(templateId);
        }
    };

    const handleEditIconClick = () => {
        setTempTitle(testTitle); // Set the temporary title to the current title when opening the modal
        onOpen();
    };

    const handleSelectRecipients = () => {
        openRecipientsModal();
    };

    const handleSaveTestTitle = () => {
        setTestTitle(tempTitle); // Update the actual title when saving
        onClose();
    };

    const handleRecipientsChange = (newRecipients) => {
        const mergedRecipients = Array.from(new Set([...selectedRecipients, ...newRecipients]));
        setSelectedRecipients(mergedRecipients);
    };

    const handleCsvUploadComplete = (recipientIds) => {
        setSelectedRecipients(recipientIds); // This should update the state in CreateTest.js
    };

    const fetchRecipientDetails = async () => {
        if (selectedRecipients.length > 0) {
            setIsLoading(true);
            try {
                const recipientDetails = await getMultipleRecipients(selectedRecipients);
                // Do something with the recipient details, e.g., store them in state
                setRecipientDetails(recipientDetails); // Assuming you have a state to hold these details
            } catch (error) {
                console.error('Error fetching recipient details:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const renderRecipientsList = () => {
        // Set a maximum height and allow for scrolling
        const listboxStyles = {
            maxHeight: '100px',
            overflowY: 'auto',
        };

        return (
            <ListboxWrapper>
                <Listbox
                    style={listboxStyles}
                    variant="flat"
                >
                    {recipientDetails.map((recipient) => (
                        <ListboxItem key={recipient._id} textValue={recipient.email}>
                            {`${recipient.first_name} ${recipient.last_name} (${recipient.email})`}
                        </ListboxItem>
                    ))}
                </Listbox>
            </ListboxWrapper>
        );
    };

    const handleSubmit = async () => {
        setIsLoading(true); // Start loading
        try {
            // Construct the payload based on the Test model
            const payload = {
                organization_id: orgInformation?._id, // Assuming the first organization is the target
                recipients: selectedRecipients, // Include the selected recipients
                group: selectedGroupIds[0], // Optional: Include if you have group data
                template_id: selectedTemplate || undefined, // Set the template ID if available
                content: value, // The content from the ReactQuill editor
                subject: testSubject, // Include the test subject
                scheduled_start: new Date().toISOString(), // Set the scheduled start time
                creation_date: new Date().toISOString(), // Set the creation date
                created_by: userData._id, // The ID of the user creating the test
                name: testTitle, // Include the test title
            };

            // Call the createTest service function with the payload
            const testId = await createTest(payload);
            navigate(`/org-admin-dashboard/tests`); // Navigate on success
            // Optionally, you can use the testId for further actions
        } catch (error) {
            console.error('Failed to create test:', error);
            setError(error); // Set the error state to display the error message
        } finally {
            setIsLoading(false); // Stop loading regardless of the outcome
        }
    };


    return (
        <div>
            <Navigation userData={userData} />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                >
                    {testTitle} <span style={{ display: 'inline-block' }}><EditIcon onClick={handleEditIconClick} /></span>
                </h1>
            </div>
            <Modal isOpen={isOpen} onClose={onClose} placement="top-center">
                <ModalContent>
                    <ModalHeader>
                        <ModalTitle>Edit Test Title</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            type="text"
                            value={tempTitle} // Use the temporary title for the input
                            onChange={(e) => setTempTitle(e.target.value)} // Update the temporary title as the user types
                            variant="bordered"
                            style={{ width: '100%' }}
                        />
                        <Button
                            color="primary"
                            onClick={handleSaveTestTitle} // Save the temporary title to the actual title
                            style={{
                                marginBottom: '1rem',
                            }}
                        >Save</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <Card
                    style={{
                        padding: '1.5rem',
                        marginBottom: '1.5rem',
                    }}
                >
                    {error && <Code style={{ marginBottom: '1rem' }} color="danger">{error.message}</Code>}

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                        <Button
                            color="primary"
                            style={{ marginRight: '10px' }}
                            onClick={handleSelectRecipients} // Open the SelectRecipientsModal
                        >
                            Select Recipients
                        </Button>
                        {selectedRecipients.length > 0 && renderRecipientsList()}
                    </div>
                    <div
                        style={{
                            maxWidth: '400px',
                        }}
                    >
                        <Input
                            placeholder="Enter subject"
                            value={testSubject}
                            onChange={(e) => setTestSubject(e.target.value)}
                            variant="bordered"
                        />
                    </div>
                    <br />
                    <div style={{ maxWidth: '400px', marginBottom: '1rem' }}>
                        <Select
                            placeholder="Select a template (optional)"
                            value={selectedTemplate}
                            onChange={(e) => handleTemplateChange(e.target.value)}
                            variant="bordered"
                        >
                            {templates.map((template) => (
                                <SelectItem key={template._id} value={template._id}>
                                    {template.name}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                    <br />
                    {/* <CKEditor
                        editor={ClassicEditor}
                        // config={{
                        //     plugins: [FullPage],
                        // }}
                        data={value}
                        onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setValue(data);
                        }}
                        style={{
                            minHeight: '400px',
                            backgroundColor: 'white',
                            marginBottom: '4rem',
                        }}
                    /> */}
                    <textarea
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        style={{
                            minHeight: '400px',
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                        }}
                    />
                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <Button color="primary" style={{ marginRight: '10px' }} onClick={handleSubmit} disabled={isLoading}>
                            {isLoading ? 'Processing...' : 'Submit'}
                        </Button>
                        <Button color="default" flat onClick={() => navigate('/org-admin-dashboard/templates')}>Cancel</Button> {/* Add your "Cancel" button here */}
                    </div>
                </Card>
            </div>
            <SelectRecipientsModal
                isOpen={recipientsModalOpen}
                onOpenChange={closeRecipientsModal}
                organizationId={orgInformation?._id}
                onSelectionChange={handleRecipientsChange} // Pass the handler to the modal
                onCsvUploadComplete={handleCsvUploadComplete} // Add this prop to handle CSV upload completion
                onGroupIdsChange={handleGroupIdsChange} // Pass the handler to track group IDs
            />
        </div>
    )
}