import { Button, Card, CardBody, Chip, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, Tab, Tabs } from "@nextui-org/react";
import GroupSelect from "./createGroupTabs/GroupSelect";
import CsvUpload from "./createGroupTabs/CsvUpload";
import { createGroup } from "../services/groupService";
import { useState } from "react";

export default function CreateGroupModal({ isOpen, onOpenChange, userData, fetchGroups, orgInformation }) {
    const [groupName, setGroupName] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const handleSubmit = async () => {
        // Check if the group name is populated
        if (!groupName.trim()) {
            setError('Please enter a group name.');
            return;
        }
        // Check if any recipients are selected
        if (selectedRecipients.length === 0) {
            setError('Please select at least one recipient.');
            return;
        }

        setLoading(true);
        setError(''); // Clear any existing errors
        try {
            await createGroup({
                group_data: { name: groupName, organization_id: orgInformation?._id, updated_by: userData._id },
                recipient_ids: selectedRecipients
            });
            // ... rest of the try block
        } catch (error) {
            // Handle errors from the createGroup call
            setError('Failed to create group. Please try again.');
            console.error('Error creating group:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleCsvUploadComplete = async (recipientIds) => {
        // No need to map over uploadedRecipients to extract IDs, as they are already provided
        setSelectedRecipients(recipientIds); // Set the selected recipients state

        // Check if the group name is populated
        if (!groupName.trim()) {
            setError('Please enter a group name.');
            return;
        }

        setLoading(true);
        setError(''); // Clear any existing errors
        try {
            // Call the createGroup service with the recipient IDs
            await createGroup({
                group_data: {
                    name: groupName,
                    organization_id: orgInformation?._id,
                    updated_by: userData._id
                },
                recipient_ids: recipientIds // Use the recipient IDs from the upload
            });
            fetchGroups(); // Refresh the group list
            onOpenChange(false); // Close the modal
            setGroupName(''); // Reset the group name
            setSelectedRecipients([]); // Reset the selected recipients
        } catch (error) {
            // Handle errors from the createGroup call
            setError('Failed to create group. Please try again.');
            console.error('Error creating group:', error);
        } finally {
            setLoading(false);
        }
    };

    let tabs = [
        {
            id: "groupSelect",
            label: "Group Select",
            content: <GroupSelect organizationId={orgInformation?._id} onSelectionChange={setSelectedRecipients} />
        },
        {
            id: "groupUpload",
            label: "CSV Upload",
            content: <CsvUpload organizationId={orgInformation?._id} onUploadComplete={handleCsvUploadComplete} />
        }
    ];

    const buttonMarginBottom = error.length > 0 ? 'mb-10' : 'mb-7';

    const buttonStyle = `${buttonMarginBottom}`

    const errorChip = () => (
        <div style={{
            marginLeft: '1.5rem',
        }}>
            <Code color="danger">{error}</Code>
        </div>
    )

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior="inside"
            placement="top-center"
            style={{
                minWidth: '800px',
                minHeight: '400px'
            }}
        >
            <ModalContent>
                <ModalHeader>Create Groups</ModalHeader>
                {error && errorChip()}
                <ModalBody>
                    <Input
                        width="50%"
                        placeholder="Enter group name"
                        variant="bordered"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    <Tabs items={tabs}>
                        {(item) => (
                            <Tab key={item.id} title={item.label} className="py-0">
                                {item.content}
                            </Tab>
                        )}
                    </Tabs>
                    <Button
                        color="primary"
                        className={buttonStyle}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Group'}
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal >
    )
}