// client/src/services/userServices.js
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase'; // Import the auth object from firebase.js

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Fetches user details by user ID.
 * 
 * @param {string} userId The unique identifier of the user to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the user's details.
 */
async function getUserById(userId) {
    return fetch(`${API_URL}/users/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching user failed:', error);
            throw error;
        });
}

/**
 * Creates a new user entry in Firebase Authentication.
 * 
 * @param {string} email The email of the user to create.
 * @param {string} password The password for the new user.
 * @returns {Promise<Object>} A promise that resolves to the user's Firebase auth data.
 */
async function createFirebaseUser(email, password) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        // User created successfully, now you can use the userCredential object.
        return userCredential.user;
    } catch (error) {
        console.error('Error creating user in Firebase:', error);
        throw error;
    }
}

/**
 * Accepts an invitation for a brand admin by making a PUT request to the server.
 * 
 * @param {string} userId The unique identifier of the user whose invitation is being accepted.
 * @returns {Promise<Object>} A promise that resolves to the server's response.
 */
async function acceptInvitation(userId) {
    return fetch(`${API_URL}/users/accept-invitation/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error accepting invitation:', error);
            throw error;
        });
}


/**
 * Fetches user details by email.
 * 
 * @param {string} email The email of the user to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the user's details.
 */
async function getUserByEmail(email) {
    return fetch(`${API_URL}/users/email/${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching user by email failed:', error);
            throw error;
        });
}

/**
 * Invites a new organization admin by making a POST request to the server.
 * 
 * @param {Object} inviteData The invitation data for the new organization admin.
 * @returns {Promise<Object>} A promise that resolves to the server's response.
 */
async function inviteOrganizationAdmin(inviteData) {
    return fetch(`${API_URL}/org-admin/invite`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify(inviteData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Error inviting organization admin:', error);
            throw error;
        });
}

/**
 * Fetches all organizations for a given brand admin by user ID.
 * 
 * @param {string} userId The unique identifier of the brand admin to retrieve organizations for.
 * @returns {Promise<Object[]>} A promise that resolves to the list of organizations.
 */
async function getClientAdminOrganizations(userId) {
    return fetch(`${API_URL}/brand-admin/organizations/${userId}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error('Fetching organizations failed:', error);
            throw error;
        });
}

// Add the new function to the export statement
export { getUserById, createFirebaseUser, acceptInvitation, getUserByEmail, inviteOrganizationAdmin, getClientAdminOrganizations };