import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { getPhishedMessagesByTimeFilter } from '../services/testService';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Emails Phished Over Time',
        },
    },
};

const PhishedEmailBarChart = ({ organizationId, timeScope }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Emails Phished',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
        ],
    });
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const days = timeScope === 'all' ? null : Number(timeScope);
                const response = await getPhishedMessagesByTimeFilter(organizationId, days);
                const labels = response.map(item => Object.keys(item)[0]);
                const data = response.map(item => Object.values(item)[0]);

                setChartData({
                    labels,
                    datasets: [
                        {
                            ...chartData.datasets[0],
                            data,
                        },
                    ],
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Stop loading regardless of outcome
            }
        };

        fetchData();
    }, [organizationId, timeScope]);

    if (loading) {
        return null; // Show loading state while data is being fetched
    }

    return <Bar options={options} data={chartData} />;
};

export default PhishedEmailBarChart;