import Papa from 'papaparse';
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Table, TableBody, TableCell, TableHeader, TableRow, Pagination, Code, TableColumn, getKeyValue, Button } from "@nextui-org/react";
import { createBulkRecipients } from '../../services/recipientServices';

export default function CsvUpload({ organizationId, onUploadComplete, closeModal }) {
    const [csvRows, setCsvRows] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [error, setError] = useState("");

    const columns = [
        {
            key: "first_name",
            label: "FIRST NAME",
        },
        {
            key: "last_name",
            label: "LAST NAME",
        },
        {
            key: "email",
            label: "EMAIL",
        },
        {
            key: "title",
            label: "TITLE",
        },
    ];

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setError(""); // Reset error message
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    const { data } = results;
                    if (data.length > 0) {
                        // Check for required columns
                        const hasRequiredColumns = columns.every(col => results.meta.fields.includes(col.key));
                        if (!hasRequiredColumns) {
                            setError("CSV must contain first_name, last_name, and email columns.");
                            setCsvRows([]);
                            return;
                        }
                        // Filter out only the required columns
                        const filteredData = data.map(row => {
                            return {
                                key: row.email,
                                organization_id: organizationId,
                                first_name: row.first_name || "",
                                last_name: row.last_name || "",
                                title: row.title || null, // title can be null
                                email: row.email || ""
                            };
                        });
                        setCsvRows(filteredData);
                    }
                }
            });
        }
    };

    const handleUpload = async () => {
        try {
            const payload = csvRows.map(({ key, ...rest }) => rest);
            const response = await createBulkRecipients(payload);
            console.log('Bulk upload successful', response);
            if (onUploadComplete) {
                onUploadComplete(response);
                closeModal(); // Close the modal after calling onUploadComplete
            }
        } catch (error) {
            setError('Failed to upload recipients.');
            console.error('Bulk upload failed:', error);
        }
    };

    const currentData = csvRows.slice((page - 1) * pageSize, page * pageSize);

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>Please ensure your CSV contains the following columns: first_name, last_name, and email. The title column is optional and can be null.</div>
            {error && <Code block color="danger">{error}</Code>}
            <Form.Control type="file" onChange={handleFileUpload} />
            <br />
            {csvRows.length > 0 && (
                <Table
                    bottomContent={
                        page > 0 ? (
                            <div className="flex w-full justify-center">
                                <Pagination
                                    isCompact
                                    showControls
                                    showShadow
                                    color="primary"
                                    page={page}
                                    total={Math.ceil(csvRows.length / pageSize)}
                                    onChange={(newPage) => setPage(newPage)}
                                />
                            </div>
                        ) : null
                    }
                >
                    <TableHeader columns={columns}>
                        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                    </TableHeader>
                    <TableBody items={currentData}>
                        {(item) => (
                            <TableRow key={item.key}>
                                {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}

            {csvRows.length > 0 && <Button color='primary' style={{ marginTop: '20px', float: 'right' }} onClick={handleUpload}>
                Upload
            </Button>}
        </div>
    );
}