// client/src/guards/BrandAdminDashboardGuard.js

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";
import { getUserByEmail } from "../services/userServices";
import BrandAdminDashboard from "../pages/BrandAdminDashboard"; // Import the dashboard component
import { Spinner } from "@nextui-org/react";

export const BrandAdminDashboardGuard = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); // Maintain loading state
    const [userInformation, setUserInformation] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                // If there is no user, redirect to login
                navigate('/login');
            } else {
                getUserByEmail(user.email)
                    .then(userData => {
                        if (userData && userData.type === 'brand_admin') {
                            // If the user is a brand admin, stop loading and render the dashboard
                            setUserInformation(userData);
                            setLoading(false);
                        } else {
                            // If the user is not a brand admin, redirect to the org admin dashboard
                            navigate('/org-admin-dashboard');
                        }
                    })
                    .catch(() => {
                        // In case of an error, also redirect to login
                        navigate('/login');
                    })
                    .finally(() => {
                        // Only set loading to false if the user is authorized
                        // Otherwise, the redirect will handle the loading state
                        if (loading) {
                            setLoading(false);
                        }
                    });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate, loading]);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner />
            </div>
        );
    }

    // If not loading and the user is authorized, render the dashboard
    return <BrandAdminDashboard userData={userInformation} setUserInformation={setUserInformation} />;
};