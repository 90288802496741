import { useLocation, useNavigate } from "react-router-dom";
import { getUserById } from "../services/userServices";
import AcceptInvitation from "../pages/AcceptInvitation";
import { useEffect, useState } from "react";
import { Spinner } from "@nextui-org/react";

export const AcceptInvitationGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setLoading(true);
        const userId = location.pathname.split('/').pop();
        if (!userId) {
            navigate('/bad-invitation');
            return; // Stop further execution if userId is not present
        }

        setUser(userId);

        getUserById(userId)
            .then(user => {
                if (user && user.invited === true) {
                    setLoading(false);
                } else {
                    navigate('/bad-invitation');
                }
            })
            .catch(() => {
                navigate('/bad-invitation');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [navigate, location.pathname]); // Added dependencies to useEffect

    return loading ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner />
        </div> : <AcceptInvitation userId={user} />;
};